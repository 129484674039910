export { default as Home } from "./home/components/index";
export { default as Assistance } from "./assistance/components/index";
export { default as Certificates } from "./certificates/components/index";
export { default as Loans } from "./loans/components/index";
export { default as Login } from "./login/components/index";
export { default as MyAnnotations } from "./myAnnotations/components/index";
export { default as MyAnnexes } from "./myAnnexes/index";
export { default as Receipts } from "./receipts/components/index";
export { default as Requests } from "./requests/components/index";
export { default as MyRequests } from "./myRequests/components/index";
export { default as DelegateAttentions } from "./delegations/components/index";
export { default as KpiIndicators } from "./kpiIndicators/components/index";
export { default as Queries } from "./queries/components/index";
export { default as MyAssistance } from "./myAssistance/components/index";
export { default as MyPending } from "./myPending/components/index";
export { default as MyPeople } from "./myPeople/components/index";
export { default as Directory } from "./directory/components/index";
export { default as Alerts } from "./alerts/components/index";
export { default as PollReview } from "./pollReview/components/index";
export { default as MyPersonalData } from "./myPersonalData/components/index";
export { default as TotemMagement } from "./totemManagement/components/index";
export { default as Vacations } from "./vacations/components/index";
export { default as Signers } from "./signers/components/index";
export { default as RequestedDocuments } from "./requestedDocuments/components/index";
export { default as SignedDocuments } from "./signedDocuments/components/index";
export { default as MyRequestsManager } from "./myRequestsManager/components/index";
export { default as MyPendingEmployer } from "./myPendingEmployer/components/index";
export { default as MyGroups } from "./groups/components/index";
export { default as GeneralSettings } from "./generalSettings/components/index";
export { default as ClassificationApplicantsTypes } from "./classificationApplicantsTypes/components/index";
export { default as TypesOfPublicationChannels } from "./typesOfPublicationChannels/components/index";
export { default as TypesOfPublicationFormats } from "./typesOfPublicationFormats/components/index";
export { default as TypesOfOnboardingActions } from "./typesOfOnboardingActions/components/index";
export { default as Actions } from "./actions/components/index";
export { default as DigitalFolderSettings } from "./digitalFolderSettings/components/index";
export { default as ExternalDocuments } from "./externalDocuments/components/index";
export { default as PollSettings } from "./pollSettings/components/index";
export { default as AccessSettings } from "./accessSettings/components/index";
export { default as AnnotationsAnalysis } from "./annotationsAnalysis/components/index";
export { default as HistoricalProcesses } from "./historicalProcesses/components/index";
export { default as CurrentProcesses } from "./currentProcesses/components/index";
export { default as DigitalFolderAnalysis } from "./digitalFolderAnalysis/components/index";
export { default as EmployeesProcesses } from "./employeesProcesses/components/index";
export { default as Geoanalysis } from "./geoanalysis/components/index";
export { default as PublicationSettings } from "./publicationSettings/components/index";
export { default as Orgchart } from "./orgchart/components/index";
export { default as PositionEditor } from "./positionEditor/components/index";
export { default as CentersSettings } from "./centersSettings/components/index";
export { default as PermissionsSettings } from "./permissionsSettings/components/index";
export { default as FamilySettings } from "./familySettings/components/index";
export { default as FunctionsSettings } from "./functionsSettings/components/index";
export { default as AdvancesSettings } from "./advancesSettings/components/index";
export { default as DocumentPublication } from "./documentPublication/components/index";
export { default as ContractTypesSettings } from "./contractTypesSettings/components/index";
export { default as ConceptGroups } from "./conceptGroups/components/index";
export { default as ContractorSettings } from "./contractorSettings/components/index";
export { default as FunctionaryTypesSettings } from "./functionaryTypesSettings/components/index";
export { default as LocationSettings } from "./locationSettings/components/index";
export { default as WorksAndTasksSettings } from "./worksAndTasksSettings/components/index";
export { default as DivisionsSettings } from "./divisionsSettings/components/index";
export { default as ConceptsFormulasFunctionsScreenSelector } from "./conceptsFormulasFunctionsScreenSelector/index";
export { default as TalentAcquisitionSettings } from "./talentAcquisitionSettings/index";
export { default as ConceptsFormulasFunctions } from "./conceptsFormulasFunctions/components/index";
export { default as AccumulatorSettings } from "./accumulatorSettings/components/index";
export { default as OrganizationalDefinitionsScreenSelector } from "./organizationalDefinitionsScreenSelector/index";
export { default as OrganizationalDefinitions } from "./organizationalDefinitions/components/index";
export { default as PayrollTypesSettings } from "./payrollTypesSettings/components/index";
export { default as AllergiesExamsDiseasesScreenSelector } from "./allergiesExamsDiseasesScreenSelector/index";
export { default as AllergiesExamsDiseases } from "./allergiesExamsDiseases/components/index";
export { default as CalculationReferences } from "./calculationReferences/components/index";
export { default as BenefitsForPlans } from "./benefitsForPlans/components/index";
export { default as OrganizationalBenefits } from "./organizationalBenefits/components/index";
export { default as EntryExitTypesScreenSelector } from "./entryExitTypesScreenSelector/index";
export { default as EntryExitTypes } from "./entryExitTypes/components/index";
export { default as RequestsSettings } from "./requestsSettings/index";
export { default as LocationSettingsBox } from "./locationSettingsBox/index";
export { default as ThirdPartySettings } from "./thirdPartySettings/index";
export { default as OrganizationStructure } from "./organizationStructure/components/index";
export { default as GeographicalStructure } from "./geographicalStructure/components/index";
export { default as IssueTypesSettingsScreenSelector } from "./issueTypesSettingsScreenSelector/index";
export { default as IssueTypesSettings } from "./issueTypesSettings/components/index";
export { default as PositionsClassificationSettings } from "./positionsClassificationSettings/components/index";
export { default as ManagementDefinition } from "./managementDefinition/index";
export { default as QueryDefinition } from "./queryDefinition/components/index";
export { default as QueryDefinitionParameters } from "./queryDefinitionParameters/components/index";
export { default as GeoQueryDefinition } from "./geoQuery/components/index";
export { default as GeoQueryDefinitionParameters } from "./geoQueryParameters/components/index";
export { default as GeneralValues } from "./generalValues/components/index";
export { default as KPIdefinition } from "./kpiDefinition/components/index";
export { default as TypesOfBenefits } from "./typesOfBenefits/components/index";
export { default as AlertsSettings } from "./alertsSettings/components/index";
export { default as AlertsSettingsScreenSelector } from "./alertsSettingsScreenSelector/index";
export { default as Notes } from "./notes/components/index";
export { default as ConceptStructure } from "./conceptStructure/components/index";
export { default as ConceptsSet } from "./conceptsSet/components/index";
export { default as ThirdPartyClassification } from "./thirdPartyClassification/components/index";
export { default as ThirdParty } from "./thirdParty/components/index";
export { default as LaborUnions } from "./laborUnions/components/index";
export { default as PaymentTypes } from "./paymentTypes/components/index";
export { default as DocumentTemplates } from "./documentTemplates/components/index";
export { default as AssetsAndDiscountsScreenSelector } from "./assetsAndDiscountsScreenSelector/components/index";
export { default as AssetsAndDiscountsCreation } from "./assetsAndDiscountsCreation/components/index";
export { default as DigitalWarehouseFileTypes } from "./digitalWarehouseFileTypes/components/index";
export { default as Schooling } from "./schooling/components/index";
export { default as Studies } from "./studies/components/index";
export { default as Specializations } from "./specializations/components/index";
export { default as Languages } from "./languages/components/index";
export { default as TrainingData } from "./trainingData/index";
export { default as HolidayTable } from "./holidayTable/components/index";
export { default as ExtraHoursTable } from "./extraHoursTable/components/index";
export { default as FestivitiesTable } from "./festivitiesTable/components/index";
export { default as BenefitsSettingsBox } from "./benefitsSettingsBox/index";
export { default as AssetsDiscountsEntryPerConcept } from "./assetsDiscountsEntryPerConcept/components/index";
export { default as AssetsDiscountsMassiveEntry } from "./assetsDiscountsMassiveEntry/components/index";
export { default as AffiliationInstitutionDefinition } from "./affiliationInstitutionDefinition/index";
export { default as RetirementDefinition } from "./retirementDefinition/components/index";
export { default as HealthDefinition } from "./healthDefinition/components/index";
export { default as AssetsDiscountsEntryPerSociety } from "./assetsDiscountsEntryPerSociety/components/index";
export { default as DigitalWarehouse } from "./digitalWarehouse/components/index";
export { default as RatingsAndGeneralValues } from "./ratingsAndGeneralValues/index";
export { default as FamilyAsignations } from "./familyAsignations/components/index";
export { default as Ratings } from "./ratings/components/index";
export { default as TaxTable } from "./taxTable/components/index";
export { default as AssetsDiscountsSummary } from "./assetsDiscountsSummary/components/index";
export { default as AttendanceReport } from "./attendanceReport/components/index";
export { default as AssetsDiscountsEntryPerConceptSet } from "./assetsDiscountsEntryPerConceptSet/components/index";
export { default as AssetsDiscountsEntryByImport } from "./assetsDiscountsEntryByImport/components/index";
export { default as SpecialProcessDefinition } from "./specialProcessDefinition/components/index";
export { default as AccountingImputations } from "./accountingImputations/components/index";
export { default as AssetsDiscountsEntryByAPI } from "./assetsDiscountsEntryByAPI/components/index";
export { default as ProcessScheduling } from "./processScheduling/components/index";
export { default as MyDesktop } from "./myDesktop/components/index";
export { default as SpecialProcessExecution } from "./specialProcessExecution/components/index";
export { default as MyPeopleSchedule } from "./myPeopleSchedule/components/index";
export { default as MySchedule } from "./mySchedule/components/index";
export { default as RebuildAccumulators } from "./rebuildAccumulators/components/index";
export { default as TimeDefinition } from "./timeDefinition/index";
export { default as TurnDefinition } from "./turnDefinition/components/index";
export { default as SchedulesDefinition } from "./schedulesDefinition/components/index";
export { default as SchedulesProgramming } from "./schedulesProgramming/components/index";
export { default as HistoricAccumulators } from "./historicAccumulators/components/index";
export { default as DistributionCost } from "./distributionCost/components/index";
export { default as AssetsDiscountsEntryByInstallments } from "./assetsDiscountsEntryByInstallments/components/"
export { default as AlertsTemplates } from "./alertsTemplates/components/index";
export { default as CalendarDefinition } from "./calendarDefinition/components/index";
export { default as ExtraHoursAuthorization } from "./extraHoursAuthorization/components/"
export { default as IssueManagementScreenSelector } from "./issueManagementScreenSelector/index";
export { default as IssueSummary } from "./issueSummary/components/index";
export { default as IssueLicences } from "./issueLicences/components/index";
export { default as IssuePermissions } from "./issuePermissions/components/index";
export { default as IssueAbsences } from "./issueAbsences/components/index";
export { default as IssueVacations } from "./issueVacations/components/index";
export { default as ConsentTypes } from "./consentTypes/components/index";
export { default as BenefitsCenter } from "./benefitsCenter/components/index";
export { default as Projects } from "./projects/components/index";
export { default as ConsentPublication } from "./consentPublication/components/index";
export { default as MyApprovedConsents } from "./myApprovedConsents/components/index";
export { default as DocumentsScreenSelector } from "./documentsScreenSelector/index";
export { default as ConsentsScreenSelector } from "./consentsScreenSelector/index";
export { default as PollScreenSelector } from "./pollScreenSelector/index";
export { default as Catalogs } from "./catalogs/components/index";
export { default as RelatedTablesDefinition } from "./relatedTablesDefinition/components/index";
export { default as ExtensibilityScreenSelector } from "./extensibilityScreenSelector/index";
export { default as SystemAndUserTablesDefinition } from "./systemAndUserTablesDefinition/components/index";
export { default as SystemAndUserTablesFieldsDefinition } from "./systemAndUserTablesFieldsDefinition/components/index";
export { default as SystemAndUserTables } from "./systemAndUserTables/components/index";
export { default as TransactionLog } from "./transactionLog/components/index";
export { default as AssistanceAnalysis } from "./assistanceAnalysis/components/index";
export { default as RecognitionPlans } from "./recognitionPlans/components/index";
export { default as PerformanceForms } from "./performanceForms/components/index";
export { default as OnboardingProfiles } from "./onboardingProfiles/components/index";
export { default as FeedbackPurpose } from "./feedbackPurpose/components/index";
export { default as GroupClassification } from "./groupClassification/components/index";
export { default as ReasonForPointAwarding } from "./reasonForPointAwarding/components/index";
export { default as CommunicationChannel } from "./communicationChannel/components/index";
export { default as RecognitionPlansAssignment } from "./recognitionPlansAssignment/components/index";
export { default as MyBenefits } from "./myBenefits/components/index";
export { default as RecognitionScreenSelector } from "./recognitionScreenSelector/index";
export { default as RecognitionAnalysis } from "./recognitionAnalysis/components/index";
export { default as NineBoxClassification } from "./nineBoxClassification/components/index";
export { default as ActionTypes } from "./actionTypes/components/index";
export { default as PollComparison } from "./pollComparison/components/index";
export { default as NineBoxScreenSelector } from "./nineBoxScreenSelector/index";
export { default as NineBoxSettings } from "./nineBoxSettings/components/index";
export { default as Feed } from "./feed/components/index";
export { default as Feedback } from "./feedback/components/index";
export { default as UserMenus } from "./userMenus/components/index";
export { default as UserMenu } from "./userMenu/components/index";
export { default as SocialBlacklist } from "./socialBlacklist/components/index";
export { default as PerformanceDefinition } from "./performanceDefinition/index";
export { default as PerformanceEvaluations } from "./performanceEvaluations/components/index";
export { default as PerformanceEvaluationsCalibration } from "./performanceEvaluationsCalibration/components/index";
export { default as TypesOfObjectives } from "./typesOfObjectives/components/index";
export { default as TypesOfScore } from "./typesOfScore/components/index";
export { default as RatingScales } from "./ratingScales/components/index";
export { default as PeriodsForObjectives } from "./periodsForObjectives/components/index";
export { default as PositionGroupsForEvaluations } from "./positionGroupsForEvaluations/components/index";
export { default as TypesOfSkillsTransversal } from "./typesOfSkillsTransversal/components/index";
export { default as TypesOfSkillsTransversalIndicators } from "./typesOfSkillsTransversalIndicators/components/index";
export { default as TypesOfSkillsSpecific } from "./typesOfSkillsSpecific/components/index";
export { default as TypesOfSkillsSpecificIndicators } from "./typesOfSkillsSpecificIndicators/components/index";
export { default as GoalsAndObjectives } from "./goalsAndObjectives/components/index";
export { default as EvaluationProcessDefinition } from "./evaluationProcessDefinition/components/index";
export { default as EvaluationProcessDefinitionAssignment } from "./evaluationProcessDefinitionAssignment/components/index";
export { default as PerformanceSummaryAndResults } from "./performanceSummaryAndResults/components/index";
export { default as PerformanceAnalyticsScreenSelector } from "./performanceAnalyticsScreenSelector/index";
export { default as PerformanceAnalyticsEvaluations } from "./performanceAnalyticsEvaluations/components/index";
export { default as PerformanceAnalyticsTalent } from "./performanceAnalyticsTalent/components/index";
export { default as CommunicationClassification } from "./communicationClassification/components/index";
export { default as Utils } from "./utils/components/index";
export { default as OnboardingMaterials } from "./onboardingMaterials/components/index";
export { default as OnboardingScreenSelector } from "./onboardingScreenSelector/index";
export { default as OnboardingProcesses } from "./onboardingProcesses/components/index";
export { default as OnboardingProcessesAssignment } from "./onboardingProcessesAssignment/components/index";
export { default as OnboardingTracking } from "./onboardingTracking/components/index";
export { default as MyOnboardingTracking } from "./myOnboardingTracking/components/index";
export { default as PerformanceAnalyticsHistoric } from "./performanceAnalyticsHistoric/components/index";